import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React, { Suspense } from 'react';
import './index.css';
import 'typeface-roboto';
import * as serviceWorker from './serviceWorker';
import { isCordovaApplication, needsiOSRtcFallback } from './services/app';
import * as pwa from './services/pwa';

declare global {
    interface CordovaDevice {
        cordova: string;
        model: string;
        platform: string;
        uuid: string;
        version: string;
        manufacturer: string;
        isVirtual: boolean;
        serial: string;
    }
    interface CordovaPlugins {
        iosrtc: {
            registerGlobals(): void;
        };
    }
    interface Cordova {
        plugins: CordovaPlugins;
    }
    interface Window {
        cordova?: Cordova;
        device?: CordovaDevice;
    }

    interface WindowEventMap {
        beforeinstallprompt: BeforeInstallPromptEvent;
    }

    /**
     * The BeforeInstallPromptEvent is fired at the Window.onbeforeinstallprompt handler
     * before a user is prompted to "install" a web site to a home screen on mobile.
     *
     * @deprecated Only supported on Chrome and Android Webview.
     */
    interface BeforeInstallPromptEvent extends Event {
        /**
         * Returns an array of DOMString items containing the platforms on which the event was dispatched.
         * This is provided for user agents that want to present a choice of versions to the user such as,
         * for example, "web" or "play" which would allow the user to chose between a web version or
         * an Android version.
         */
        readonly platforms: Array<string>;

        /**
         * Returns a Promise that resolves to a DOMString containing either "accepted" or "dismissed".
         */
        readonly userChoice: Promise<{
            outcome: 'accepted' | 'dismissed';
            platform: string;
        }>;

        /**
         * Allows a developer to show the install prompt at a time of their own choosing.
         * This method returns a Promise.
         */
        prompt(): Promise<void>;
    }
}

window.addEventListener('beforeinstallprompt', (evt) => {
    evt.preventDefault();
    // evt.prompt();
    console.log(evt);
});

const renderApp = async (): Promise<void> => {
    const ReactDOM = await import('react-dom');
    const Provider = React.lazy(() => import('./components/Provider'));

    ReactDOM.render(
        <React.StrictMode>
            <Suspense fallback={null}>
                <Provider />
            </Suspense>
        </React.StrictMode>,
        document.getElementById('root')
    );
};

if (isCordovaApplication()) {
    // Ensure cordova is ready, if we are running the app
    document.addEventListener('deviceready', () => {
        if (needsiOSRtcFallback()) {
            (window.cordova as Cordova).plugins.iosrtc.registerGlobals();
        }

        renderApp();
    });
} else {
    serviceWorker.register({
        onUpdate: () => {
            alert('Eine Aktualisierung ist verfügbar. Bitte starten Sie den Browser bzw. die Anwendung erneut.');
        }
    });

    pwa.register();
    renderApp();
}
