declare global {
    interface WindowEventMap {
        beforeinstallprompt: BeforeInstallPromptEvent;
    }

    /**
     * The BeforeInstallPromptEvent is fired at the Window.onbeforeinstallprompt handler
     * before a user is prompted to "install" a web site to a home screen on mobile.
     *
     * @deprecated Only supported on Chrome and Android Webview.
     */
    interface BeforeInstallPromptEvent extends Event {
        /**
         * Returns an array of DOMString items containing the platforms on which the event was dispatched.
         * This is provided for user agents that want to present a choice of versions to the user such as,
         * for example, "web" or "play" which would allow the user to chose between a web version or
         * an Android version.
         */
        readonly platforms: Array<string>;

        /**
         * Returns a Promise that resolves to a DOMString containing either "accepted" or "dismissed".
         */
        readonly userChoice: Promise<{
            outcome: 'accepted' | 'dismissed';
            platform: string;
        }>;

        /**
         * Allows a developer to show the install prompt at a time of their own choosing.
         * This method returns a Promise.
         */
        prompt(): Promise<void>;
    }
}

type Callback = (available: boolean) => void;

let installationPromptEvent: BeforeInstallPromptEvent | null = null;
const promptChangeCallbacks: Callback[] = [];

export const canPrompt = (): boolean => installationPromptEvent != null;

const dispatchPromptChangeEvent = () => {
    for (const callback of promptChangeCallbacks) {
        callback(canPrompt());
    }
};

export const addPromptChangeListener = (callback: Callback) => {
    promptChangeCallbacks.push(callback);
};

export const removePromptChangeListener = (callback: Callback) => {
    for (let i = 0, l = promptChangeCallbacks.length; i < l; i++) {
        if (promptChangeCallbacks[i] === callback) {
            promptChangeCallbacks.splice(i, 1);
            return;
        }
    }
};

export const register = () => {
    window.addEventListener('beforeinstallprompt', (event) => {
        event.preventDefault();
        installationPromptEvent = event;
        dispatchPromptChangeEvent();
    });
};

export const prompt = (): Promise<boolean> => {
    if (installationPromptEvent == null) {
        return Promise.resolve(false);
    }

    const promise = new Promise<boolean>((resolve) => {
        installationPromptEvent?.userChoice.then((choiceResult) => {
            if (choiceResult.outcome === 'accepted') {
                resolve(true);
            } else {
                resolve(false);
            }
        });

        installationPromptEvent = null;
        dispatchPromptChangeEvent();
    });

    installationPromptEvent.prompt();
    return promise;
};
