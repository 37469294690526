export const isStandaloneApplication = () =>
    window.matchMedia('(display-mode: standalone)').matches || // chrome, android, etc.
    (window.navigator as any).standalone; // safari only

export const isCordovaApplication = () => window.cordova != null;

export const isPlatform = (platform: string) => window.device != null && window.device.platform === platform;

export const isCordovaAndroid = () => isPlatform('Android');

export const isCordovaiOS = () => isPlatform('iOS');

export const isiOS = () => {
    return (
        ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
            navigator.platform
        ) ||
        // iPad on iOS 13 detection
        (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    );
};

export const getiOSVersion = () => {
    const agent = window.navigator.userAgent;
    const start = agent.indexOf('OS ');
    if ((agent.indexOf('iPhone') > -1 || agent.indexOf('iPad') > -1) && start > -1) {
        return window.Number(agent.substr(start + 3, 3).replace('_', '.'));
    }
    return 0;
};

export const supportsFullscreen = () => !isCordovaApplication();

export const isCordovaPermissionPluginLoaded = () =>
    isCordovaApplication() &&
    (window.cordova as Cordova).plugins != null &&
    (window.cordova as Cordova).plugins.permissions != null;

export const isiOSRtcPluginLoaded = () =>
    isCordovaApplication() &&
    (window.cordova as Cordova).plugins != null &&
    (window.cordova as Cordova).plugins.iosrtc != null;

export const needsiOSRtcFallback = () => isCordovaiOS() && isiOSRtcPluginLoaded();

export const supportsDeviceChangeEvent = () =>
    navigator.mediaDevices != null &&
    navigator.mediaDevices.addEventListener != null &&
    navigator.mediaDevices.removeEventListener != null;

export const supportsMediaDevices = () =>
    navigator.mediaDevices != null && navigator.mediaDevices.enumerateDevices != null;

export const supportsCordovaPermissionsPlugin = () => isCordovaPermissionPluginLoaded() && isCordovaAndroid();

export const isSupportedDevice = () =>
    isCordovaApplication() || (supportsMediaDevices() && (!isiOS() || getiOSVersion() > 12));
